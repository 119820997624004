<div style="margin-top: 10px; margin-bottom: 10px;">
    <!-- buildType 1: Universal 2 -->
    <span *ngIf="build.buildType == 1">
        <img src="/assets/universal_2_icon.png" width="20px">&nbsp; Universal 2 for <strong class="dashed"
            ngbTooltip="Macs with M1 or later processors. Manufactured since 2020.">Apple
            Silicon</strong>
        and <strong class="dashed" ngbTooltip="Macs with 64-bit Intel processors. Manufactured since 2008.">64-Bit
            Intel</strong>
        Macs
    </span>
    <!-- buildType 2: Universal 1 -->
    <span *ngIf="build.buildType == 2">
        <img src="/assets/universal_1_icon.png" width="20px">&nbsp; Universal 1 for <strong class="dashed"
            ngbTooltip="Macs with 32-bit or 64-bit Intel processors. Manufactured from 2005-2023.">Intel</strong>
        and <strong class="dashed"
            ngbTooltip="Macs with PowerPC processors. Manufactured from 1994-2005.">PowerPC</strong>
        Macs
    </span>
    <!-- buildType 3: Apple Silicon -->
    <span *ngIf="build.buildType == 3">
        <img src="/assets/icon_apple.png" width="20px">&nbsp; Build for <strong class="dashed"
            ngbTooltip="Macs with M1 or later processors. Manufactured since 2020.">Apple Silicon</strong>
        Macs.
    </span>
    <!-- buildType 4: Intel 64-bit -->
    <span *ngIf="build.buildType == 4">
        <img src="/assets/icon_intel.png" width="20px">&nbsp; Build for <strong class="dashed"
            ngbTooltip="Macs with 64-bit Intel processors. Manufactured from 2008-2023.">64-Bit Intel</strong> Macs.
        Playable on <strong class="dashed" ngbTooltip="Macs with M1 or later processors. Manufactured since 2020.">Apple
            Silicon</strong> Macs via Rosetta 2.
    </span>
    <!-- buildType 5: Intel 32-Bit -->
    <span *ngIf="build.buildType == 5">
        <img src="/assets/icon_intel.png" width="20px">&nbsp; Build for <strong class="dashed"
            ngbTooltip="Macs with 32-bit Intel processors. Manufactured from 2005-2007.">32-Bit Intel</strong> Macs.
        Playable on <strong class="dashed" ngbTooltip="Macs with 64-bit Intel processors. Manufactured from 2008-2023.">64-bit Intel</strong> Macs running macOS 10.14 or prior.
    </span>
    <!-- buildType 6: PowerPC -->
    <span *ngIf="build.buildType == 6">
        <img src="/assets/icon_ppc.png" width="20px">&nbsp; Build for <strong class="dashed"
        ngbTooltip="Macs with PowerPC processors. Manufactured from 1994-2005.">PowerPC</strong> Macs.
        Playable on <strong class="dashed" ngbTooltip="Macs with 64-bit Intel processors. Manufactured from 2008-2023.">64-bit Intel</strong> Macs running Mac OS X 10.6 or prior or <strong class="dashed"
        ngbTooltip="Macs with 32-bit Intel processors. Manufactured from 2005-2007.">32-Bit Intel</strong> Macs via Rosetta 1.
    </span>
    &nbsp;
    <span *ngIf="build.earlyAccess">
        <div class="badge bg-warning" style="height: 20px;">Early Access</div>&nbsp;
    </span>
    <span *ngIf="build.signed">
        <div class="badge bg-info" style="height: 20px;">Signed</div>&nbsp;
    </span>
    <span *ngIf="build.notarized">
        <div class="badge bg-success" style="height: 20px;">Notarized</div>&nbsp;
    </span>
    <span *ngIf="build.fullGame">
        <div class="badge bg-danger" style="height: 20px;">Full Game</div>&nbsp;
    </span>
    <span *ngIf="build.adHocSigned">
        <div class="badge bg-adhoc" style="height: 20px;"
            ngbTooltip="On first run you may need to right-click to open. See the FAQ for more details.">Ad Hoc Signed
        </div>&nbsp;
    </span>
    <span *ngIf="build.MSPBuild && !build.OfficialMSPBuild">
        <div class="badge bg-dark" style="height: 20px;" ngbTooltip="Mac Source Ports maintains this buld.">Mac Source
            Ports Build<span *ngIf="build.MSPGitHub">&nbsp;&nbsp;<a href="{{build.MSPGitHub}}" target="_new"><fa-icon
                        [icon]="faGitHub"></fa-icon></a></span></div>&nbsp;
    </span>
    <span *ngIf="build.OfficialMSPBuild">
        <div class="badge bg-official" style="height: 20px;"
            ngbTooltip="Mac Source Ports is the official maintainer of the build for this source port."><img
                src="/assets/favicon.png" width="12px" style="margin-top: -3px;">&nbsp;Official Mac Source Ports
            Build<span *ngIf="build.MSPGitHub">&nbsp;&nbsp;<a href="{{build.MSPGitHub}}" target="_new"><fa-icon
                        [icon]="faGitHub"></fa-icon></a></span></div>&nbsp;
    </span>
    <span *ngIf="!build.MSPBuild">
        <div class="badge bg-light" style="height: 20px;"
            ngbTooltip="A third party builds and maintains this app. Mac Source Ports is merely linking to it.">Third
            Party Build</div>&nbsp;
    </span>
    <br>
    <div style="margin-top: 5px;"><fa-icon [icon]="faDownload"></fa-icon>&nbsp; <strong><a href="{{build.downloadURL}}">Download</a></strong>&nbsp;&nbsp;<span style="font-size: small;">Version {{build.version}}</span></div>
    <div style="font-size: smaller;margin-top: 3px;">Build date: {{build.buildDate}}</div>
</div>