<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="container-fluid">
    <span class="navbar-brand"><a class="nav-link" routerLink="/" (click)="collapsed = !collapsed">Mac Source
        Ports</a></span>
    <button class="navbar-toggler" type="button" aria-controls="navbarContent" [attr.aria-expanded]="!collapsed"
      aria-label="Toggle navigation" (click)="collapsed = !collapsed">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-collapse" [class.collapse]="collapsed" id="navbarContent">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" routerLink="/games" (click)="collapsed = !collapsed">Games</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/sourceports" (click)="collapsed = !collapsed">Source Ports</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/utilities" (click)="collapsed = !collapsed">Utilities</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/faq" (click)="collapsed = !collapsed">FAQ</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/about" (click)="collapsed = !collapsed">About</a>
        </li>
      </ul>
      <ul class="navbar-nav ms-auto">
        <li class="nav-item">
          <a href="https://twitter.com/MacSourcePorts" target="_new">
            <fa-icon [icon]="faTwitter" style="font-size: larger;"></fa-icon>
          </a>
          &nbsp;
          <a href="https://mastodon.social/@MacSourcePorts" target="_new">
            <fa-icon [icon]="faMastodon" style="font-size: larger;"></fa-icon>
          </a>
          &nbsp;
          <a href="https://www.threads.net/@macsourceports" target="_new">
            <fa-icon [icon]="faThreads" style="font-size: larger;"></fa-icon>
          </a>
          &nbsp;
          <a href="https://bsky.app/profile/macsourceports.bsky.social" target="_new">
            <fa-icon [icon]="faBluesky" style="font-size: larger;"></fa-icon>
          </a>
          &nbsp;
          <a href="https://discord.gg/5xqUxWWCQa" target="_new">
            <fa-icon [icon]="faDiscord" style="font-size: larger;"></fa-icon>
          </a>
          &nbsp;
          <a href="https://instagram.com/MacSourcePorts" target="_new">
            <fa-icon [icon]="faInstagram" style="font-size: larger;"></fa-icon>
          </a>
          &nbsp;
          <a href="https://www.youtube.com/channel/UCIwGqtTNhsr8PUyQ3YvbEUw" target="_new">
            <fa-icon [icon]="faYoutube" style="font-size: larger;"></fa-icon>
          </a>
          &nbsp;
          <a href="https://www.twitch.tv/macsourceports" target="_new">
            <fa-icon [icon]="faTwitch" style="font-size: larger;"></fa-icon>
          </a>
          &nbsp;
          <a href="https://www.tiktok.com/@macsourceports" target="_new">
            <fa-icon [icon]="faTikTok" style="font-size: larger;"></fa-icon>
          </a>
          &nbsp;
          <a href="https://www.facebook.com/MacSourcePorts/" target="_new">
            <fa-icon [icon]="faFacebook" style="font-size: larger;"></fa-icon>
          </a>
          &nbsp;
          <a href="https://schnapple.com/category/macsourceports/" target="_new">
            <fa-icon [icon]="faWordpress" style="font-size: larger;"></fa-icon>
          </a>
          &nbsp;
          <a href="https://github.com/MacSourcePorts" target="_new">
            <fa-icon [icon]="faGithub" style="font-size: larger;"></fa-icon>
          </a>
          &nbsp;
          <a href="/rss" target="_new">
            <fa-icon [icon]="faRss" style="font-size: larger;"></fa-icon>
          </a>
          &nbsp;
          <a href="https://ko-fi.com/macsourceports" target="_new" style="text-decoration: none;">
            <span class="btn btn-secondary my-2 my-sm-0 btn-sm" style="font-size: small; position: relative; bottom: 3px;">Buy me a coffee&nbsp;&nbsp;<fa-icon [icon]="faCoffee" style="font-size: larger;"></fa-icon></span>
          </a>
        </li>
      </ul>
      <form class="d-flex">
      </form>
    </div>
  </div>
</nav>

<!-- This will need to go in a component -->
<div class="container" style="margin-top: 50px;">
  <router-outlet></router-outlet>
  <br>
  <div style="text-align: center;">Copyright &copy; {{currentYear}} MacSourcePorts.com&nbsp;&nbsp;|&nbsp;&nbsp;<a
      href="mailto:tom@macsourceports.com">Contact Us</a></div>
  <br>
</div>